import { useState, useRef, useEffect } from 'react'
import icon_tips from '../../assets/tips/icon_tips.svg'
import icon_tips_more from '../../assets/tips/icon_tips_more.svg'
import icon_type from '../../assets/login/icon_type.svg'
import icon_down from '../../assets/login/icon_down.svg'
import icon_user from '../../assets/login/icon_user.svg'
import icon_password from '../../assets/login/icon_password.svg'
import icon_eyes from '../../assets/login/icon_eyes.svg'
import icon_eyes_open from '../../assets/login/icon_eyes_open.svg'
import icon_code from '../../assets/banner/icon_code.png'
import {
    Button,
    Tabs,
    Form,
    Input,
    Checkbox,
    message,
    Alert,
    Spin,
    Select,
    Modal
} from 'antd'
import {
    getQRCode,
    checkQRCode,
    fakeAccountLogin,
    doSendPhoneCodePlatForm
} from '../../utils/api'
import {
    applyForRial,
    getUpdateTip,
} from '../../utils/apiC'
import {
    SyncOutlined,
    LeftOutlined
} from '@ant-design/icons'
import './login.less'
import common, { trim } from '../../utils/common'
const { TabPane } = Tabs
const { Option } = Select
function Login(props) {
    const timerID = useRef();
    const timerIDCode = useRef();
    const [form] = Form.useForm();
    const [formLogin] = Form.useForm();
    const [formCode] = Form.useForm();
    const { onChange } = props
    const [loading, setLoading] = useState(true)
    const [modalType, setModalType] = useState(1)
    const [modalKey, setModalKey] = useState('dongmi')
    const [arcodeFailure, setArcodeFailure] = useState(true)
    const [loginType, setLoginType] = useState('dongmi')
    const [arcodeImg, setArcodeImg] = useState('')
    const [loginError, setLoginError] = useState('')
    const [timeCode, setTimeCode] = useState(60)
    const [tryuseVisible, setTryuseVisible] = useState(false)
    const [ info, setInfo ] = useState({sShowDate: '', sId: '', sToWho: '', sContent: '', sPublisher: '', sPublishTime: '', iShowWay: 1})
    const [ visible, setVisble ] = useState(false)

    const callback = key => {
        setModalKey(key)
        if( key === 'dongmi' ){
            setLoginError('')
            setArcodeFailure(true);
            setArcodeImg('')
        } else {
            _getQRCode()
            clearInterval(timerIDCode.current)
            setTimeCode(60)
        }
    }
    const onFinish = (fieldsValue) => {
        const data = {
            'eType': 1,
            "sAccountId": trim(fieldsValue.username),
            "sPassword": trim(fieldsValue.password),
            "stXPTerminalInfo": {
                iVersion: modalType,
                iSubType: 0,
                iTerminalType: 0
            }
        }
        fakeAccountLogin(data).then((res)=>{
            if(res){
                localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stXPUserInfo))
                localStorage.setItem("rongzheng_dongmi_login",JSON.stringify({
                    username: fieldsValue.username,
                    password: fieldsValue.password,
                    remember: fieldsValue.remember,
                }))
                window.location.href = common  + '/zhiku/'
            }
        })
    }
    const onFinishFailed = (values) => {
        console.log(values)
    }
    const onTelephoneFinish = (fieldsValue) => {
        const reg =/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/
        if(!reg.test(fieldsValue.telephone)){
            formCode.setFields([
                {
                    errors: ['手机号码格式不正确'],
                    name: ['telephone']
                }
            ])
            return;
        }
        const data = {
            sAccountId: fieldsValue.telephone,
            sPassword: fieldsValue.code,
            eType: 2,
            stXPTerminalInfo: {
                iVersion: modalType,
                iSubType: 0,
                iTerminalType: 0
            }
        }
        fakeAccountLogin(data).then(res=>{
            if(res){
                localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stXPUserInfo))
                window.location.href = common + '/zhiku/'
            }
        })

    }
    const handleTryuseCancel = () => {
        setTryuseVisible(false)
        onChange(false)
        form.setFieldsValue({
            sCompany: "",
            sUserName: "",
            sTitle: "",
            sPhone: "",
            sMail: ""
        })
    }
    const onTelephoneFinishFailed = () => {}
    const onTryuseFinish = (fieldsValue) => {
        const data = {
            ...fieldsValue,
            iProductType: modalType
        }
        applyForRial(data).then((response)=>{
            const  res = response.data
            if(res){
                handleTryuseCancel()
                message.success('提交成功');
            }
        })
    }
    const onTryuseFinishFailed = () => {}
    const _getQRCode = () => {
        setLoading(false)
        clearInterval(timerID.current)
        setLoading(true)
        const data = {}
        getQRCode(data).then(res => {
            setLoading(false)
            if (res) {
                const content = res
                if (content.vQRData.length) {
                    // const array = new Uint8Array(content.vQRData)
                    // const blob = new Blob([array], {
                    //     type: 'application/octet-binary'
                    // })
                    // let oFileReader = new FileReader()
                    // oFileReader.readAsDataURL(blob, 'image/png')
                    // oFileReader.onloadend = e => {
                    //     setArcodeImg(e.target.result)
                    //     setArcodeFailure(false);
                    // }
                    setArcodeImg('data:image/png;base64,'+content.vQRData)
                    setArcodeFailure(false);
                    timerID.current = setInterval(() => {
                        const date = Date.parse(new Date());
                        if(content.iExpireTime<date){
                            setArcodeFailure(true);
                            clearInterval(timerID.current);
                        }else{
                            _checkQRCode({sUuid:content.sUuid});
                        }
                    }, 1000);
                }
            }
        })
    }
    const _checkQRCode = (params, value)=>{
        const paramsData = {...params}
        checkQRCode(paramsData).then(res=>{
            if(res){
                const _data = res;
                if(_data.iStatus===0){
                    clearInterval( timerID.current);
                    localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stIdentyInformation.stXPUserInfo))
                    window.location.href = common + '/zhiku/';
                }
            }
        })
    }
    const forgetPassword = e => {
        e.preventDefault()
        setLoginError('')
        setLoginType('financial')
    }
    const forgetPasswordOther = e => {
        e.preventDefault()
        setLoginError('')
        setLoginType('dongmi')
        clearInterval(timerIDCode.current)
        setTimeCode(60)
    }
    const getPhoneCode = ()=>{
        const reg =/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/
        if(!formCode.getFieldValue("telephone")){
            formCode.setFields([
                {
                    errors: ['请填写手机号'],
                    name: ['telephone']
                }
            ])
            return;
        }
        if(!reg.test(formCode.getFieldValue("telephone"))){
            formCode.setFields([
                {
                    errors: ['手机号码格式不正确'],
                    name: ['telephone']
                }
            ])
            return;
        }
        const data = {sPhoneNum:formCode.getFieldValue("telephone"), stXPTerminalInfo:{iVersion: modalType}}
        doSendPhoneCodePlatForm(data).then(res=>{
            if(res){
                setTimeCode(59)
                timerIDCode.current = setInterval(()=>{
                    setTimeCode((time)=> {
                        if(time === 0){
                            clearInterval(timerIDCode.current)
                            return 60
                        } else {
                            return --time
                        }
                    })
                }, 1000);
            }
        })
    }
    const handleChange = (value)=>{
        setModalType(value)
        localStorage.setItem("rongzheng_version",JSON.stringify({
            modalType:value
        }))
        getUpdateTipInfo(value)
    }
    const getUpdateTipInfo = (iProductType) => {
        const paramsData = {iProductType, iReqFrom: 1}
        getUpdateTip(paramsData).then((response)=>{
            const  res = response.data.rsp
            if(res){
                setInfo(res.stUpdateTip)
            }
        })
    }
    useEffect(()=> {
        const data = localStorage.getItem("rongzheng_version")?JSON.parse(localStorage.getItem("rongzheng_version")):localStorage.getItem("rongzheng_version")
        if(data){setModalType(data.modalType)}
        const user = localStorage.getItem("rongzheng_dongmi_login")?JSON.parse(localStorage.getItem("rongzheng_dongmi_login")):localStorage.getItem("rongzheng_dongmi_login")
        if(user){
            // const current = new Date().getTime();
            // formLogin.setFieldsValue({
            //     username:  current < user.time ? user.username : "",
            //     password: current < user.time ? user.password : "",
            //     remember: current < user.time ? true : false
            // })
            formLogin.setFieldsValue({
                username:  !user.remember ? '' : user.username,
                password: !user.remember ? '' : user.password,
                remember: user.remember
            })
        } else {
            formLogin.setFieldsValue({
                username:  '',
                password: '',
                remember: true
            })
        }
        getUpdateTipInfo(data ? data.modalType : modalType)
        return () => {
            // 组件销毁时，清除定时器
            clearInterval(timerID.current)
            clearInterval(timerIDCode.current)
        };
    }, [])
    return (
        <main className="login">
            { tryuseVisible ?
            <div className="tryuse">
                <div className="tryuse-title">
                    <div className="tryuse-back" onClick={handleTryuseCancel}>
                        <LeftOutlined /> 返回
                    </div>
                    <div>智库 · 申请试用</div>
                </div>
                <div className="tryuse-content">
                    <Form
                        form={form}
                        onFinish={onTryuseFinish}
                        onFinishFailed={onTryuseFinishFailed}
                    >
                        <Form.Item
                            name="sCompany"
                            rules={[
                                {
                                    required: true,
                                    message: `请输入公司名称!`
                                }
                            ]}
                        >
                            <Input size="large" placeholder={`请输入公司名称`} />
                        </Form.Item>

                        <Form.Item
                            name="sUserName"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入姓名!'
                                }
                            ]}
                        >
                            <Input size="large" placeholder="请输入姓名" />
                        </Form.Item>

                        <Form.Item
                            name="sTitle"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入职位!'
                                }
                            ]}
                        >
                            <Input size="large" placeholder="请输入职位" />
                        </Form.Item>

                        <Form.Item
                            name="sPhone"
                            rules={[
                                {
                                    pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                                    message: '请输入正确的手机号格式!'
                                },
                                {
                                    required: true,
                                    message: '请输入手机号!'
                                }
                            ]}
                        >
                            <Input size="large" placeholder="请输入手机号" />
                        </Form.Item>

                        <Form.Item
                            name="sMail"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入邮箱!'
                                }
                            ]}
                        >
                            <Input size="large" placeholder="请输入邮箱" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                            >
                                提交申请
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="tryuse-desc">
                        审核通过后，我们将为您开通试用账号
                    </div>
                </div>
            </div>
            : (
                <div className="login-wrapper">
                    <div className="login">
                        <Tabs activeKey={modalKey} onChange={callback}>
                            <TabPane tab="账号登录" key="dongmi">
                                <div style={loginType === 'dongmi' ? {display: 'block'} : {display: 'none'}} className="tryuse-content">
                                    {
                                        loginError !== '' && <Alert style={{marginBottom:20}} message={loginError} type="error" showIcon />
                                    }
                                    <div className="selectType">
                                        <img src={icon_type}/>
                                        <Select suffixIcon={<img src={icon_down}/>} size="large" value={modalType} style={{ width: '100%', marginBottom: 24 }} onChange={handleChange}>
                                            <Option value={2}>智库免费版</Option>
                                            <Option value={1}>智库收费版</Option>
                                            <Option value={3}>智库IPO版</Option>
                                        </Select>
                                    </div>
                                    <Form
                                        form={formLogin}
                                        initialValues={{ remember: false }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        `请输入手机号/智库账号!`
                                                }
                                            ]}
                                        >
                                            <Input
                                                prefix={<img src={icon_user}/>}
                                                size="large"
                                                placeholder={`请输入手机号/智库账号`}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入密码!'
                                                }
                                            ]}
                                        >
                                            <Input.Password
                                                prefix={<img src={icon_password}/>}
                                                iconRender={(visible)=> !visible ? <img src={icon_eyes}/> : <img src={icon_eyes_open}/>}
                                                size="large"
                                                placeholder="请输入密码"
                                            />
                                        </Form.Item>
                                        <Form.Item style={info.sId !== '' ? {marginBottom: 0} : {marginBottom: 16}}>
                                            <Form.Item
                                                name="remember"
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                <Checkbox>
                                                    记住我的登录资料
                                                </Checkbox>
                                            </Form.Item>
                                            <a
                                                className="login-form-forgot"
                                                href="/#"
                                                onClick={forgetPassword}
                                            >
                                                使用验证码登录
                                            </a>
                                        </Form.Item>
                                        {
                                            info.sId !== '' &&
                                            <div  className='tips'>
                                                <div><img src={icon_tips}/>{info.sShowDate}系统升级公告 </div>
                                                <div onClick={()=>setVisble(true)}>详情<img src={icon_tips_more}/></div>
                                            </div>
                                        }
                                        <Form.Item>
                                            <Button
                                                size="large"
                                                type="primary"
                                                htmlType="submit"
                                                className="login-form-button"
                                            >
                                                登录
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div style={loginType !== 'dongmi' ? {display: 'block'} : {display: 'none'}} className="tryuse-content">
                                    {
                                        loginError !== '' && <Alert style={{marginBottom:20}} message={loginError} type="error" showIcon />
                                    }
                                    <div className="selectType">
                                        <img src={icon_type}/>
                                        <Select suffixIcon={<img src={icon_down}/>} size="large" value={modalType} style={{ width: '100%', marginBottom: 24 }} onChange={handleChange}>
                                            <Option value={2}>智库免费版</Option>
                                            <Option value={1}>智库收费版</Option>
                                            <Option value={3}>智库IPO版</Option>
                                        </Select>
                                    </div>
                                    <Form
                                        form={formCode}
                                        onFinish={onTelephoneFinish}
                                        onFinishFailed={
                                            onTelephoneFinishFailed
                                        }
                                    >
                                        <Form.Item
                                            name="telephone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入手机号!'
                                                }
                                            ]}
                                        >
                                            <Input prefix={<img src={icon_user}/>} size="large" placeholder="请输入手机号" />
                                        </Form.Item>
                                        <Form.Item style={{marginBottom: 24, position: "relative"}}>
                                            <Form.Item
                                                name="code"
                                                noStyle
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            '请输入验证码!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    prefix={<img src={icon_password}/>}
                                                    size="large"
                                                    placeholder="请输入验证码"
                                                    style={{ width: '100%' }}
                                                />

                                            </Form.Item>
                                            <Button
                                                className="getCode"
                                                disabled={timeCode !== 60 }
                                                onClick={getPhoneCode}
                                            >
                                                { timeCode === 60 ? '获取验证码' : "倒计时" + timeCode + "s"}
                                            </Button>
                                        </Form.Item>
                                        <Form.Item style={info.sId !== '' ? {marginBottom: 0,} : {marginBottom: 16}}>
                                            <div></div>
                                            <a
                                                className="login-form-forgot"
                                                href="/#"
                                                onClick={forgetPasswordOther}
                                            >
                                                使用密码登录
                                            </a>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button
                                                size="large"
                                                type="primary"
                                                htmlType="submit"
                                                className="login-form-button"
                                            >
                                                登录
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                </div>
                            </TabPane>
                            <TabPane tab="扫码登录" key="arcode">
                                <div className="arcode">
                                    {arcodeFailure ? (
                                        <div className="failure">
                                            {
                                                loading ? <div className="spin"><Spin /></div> :
                                                <div>
                                                    <img src={arcodeImg} alt="" />
                                                    <div>
                                                        <p>二维码已失效</p>
                                                        <p onClick={_getQRCode}>
                                                            <SyncOutlined />
                                                            刷新
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        <div className="saoma">
                                            <div><img src={arcodeImg} alt="" /></div>
                                        </div>
                                    )}
                                    <div className="code_tip" style={{marginTop:20, fontSize: 16}}><img style={{marginRight: 2}} width="20" src={icon_code}/>请打开智库APP扫描二维码登录</div>
                                </div>
                            </TabPane>
                        </Tabs>
                        <div style={{textAlign:"center"}}>还没有账号？点击<span onClick={()=>{setTryuseVisible(true);onChange(true)}} style={{color:"#2EA7E0", cursor: "pointer"}}>申请试用</span></div>
                    </div>
                </div>
            )}
            <Modal visible={visible} title={'系统更新公告'} footer={null} onCancel={()=>setVisble(false)}>
                <div className="modal">
                    { info.sToWho !== '' && <div className={'sToWho'}>尊敬的{info.sToWho}：</div> }
                    <div className='sContent'>{info.sContent}</div>
                    {info.sPublisher !== '' &&  <div className={'sPublisher'}>{info.sPublisher}</div>}
                    {info.sPublishTime !== '' &&  <div className={'sPublishTime'}>{info.sPublishTime}</div>}
                </div>
            </Modal>
        </main>
    )
}
export default Login
