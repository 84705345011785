import requestJava from './request';
const hostJavaRongz = `${window.location.origin}/rzwebgateway`
export async function updateUserPhoneNum(params) {
    return requestJava(`${hostJavaRongz}/user/accountInfo/updateUserPhoneNum`, {
        method: 'POST',
        data: params
    });
}
//验证密码
export async function checkUserPassword(params) {
    return requestJava(`${hostJavaRongz}/user/accountInfo/checkUserPassword`, {
        method: 'POST',
        data: params
    });
}
//忘记密码
export async function retrievePasswordPlatForm(params) {
    return requestJava(`${hostJavaRongz}/auth/user/retrievePassword`, {
        method: 'POST',
        data: params
    });
}
//验证短信验证码
export async function verifyPhoneCodePlatForm(params) {
    return requestJava(`${hostJavaRongz}/auth/user/verifyPhoneCode`, {
        method: 'POST',
        data: params
    });
}

//检查二维码验证是否通过
export async function checkQRCode(params) {
    return requestJava(`${hostJavaRongz}/auth/user/checkQRCodePlatForm`, {
        method: 'POST',
        data: params
    });
}
//web端获取二维码图片
export async function getQRCode() {
    return requestJava(`${hostJavaRongz}/auth/user/getQRCodePlatForm`, {
        method: 'get',
        params: {}
    });
}
//发送短信验证码
export async function doSendPhoneCodePlatForm(params) {
    return requestJava(`${hostJavaRongz}/auth/user/sendPhoneCode`, {
        method: 'POST',
        data: params
    });
}
//信披平台登录-验证码登录
export async function loginPlatForm(params) {
    return requestJava(`${hostJavaRongz}/user/accountInfo/loginPlatForm`, {
        method: 'POST',
        data: params
    });
}
//申请试用
export async function applyForRial(params) {
    return requestJava(`${hostJavaRongz}/user/accountInfo/applyForRial`, {
        method: 'POST',
        data: params
    });
}
//登录
export async function fakeAccountLogin(params) {
    return requestJava(`${hostJavaRongz}/auth/user/login`, {
        method: 'POST',
        data: params
    });
}
export async function getOfflinePCProductListFetch(params) {
    return requestJava(`${hostJavaRongz}/user/accountInfo/getOfflinePCProductList`, {
        method: 'POST',
        data: params
    });
}