import { useState, useRef, useEffect } from 'react'
import logo from '../../assets/header/logo.svg'
import {
    Button,
    Tabs,
    Form,
    Input,
    Checkbox,
    message,
    Alert,
    Spin,
    Select,
    Modal
} from 'antd'
import {
    getQRCode,
    checkQRCode,
    fakeAccountLogin,
    doSendPhoneCodePlatForm
} from '../../utils/api'
import {
    applyForRial,
    getUpdateTip,
} from '../../utils/apiC'
import {
    SyncOutlined,
    LeftOutlined
} from '@ant-design/icons'
import './phone.less'
import common, { trim } from '../../utils/common'
import moment from "moment/moment";
const { TabPane } = Tabs
const { Option } = Select
function Login(props) {
    const timerID = useRef();
    const timerIDCode = useRef();
    const [form] = Form.useForm();
    const [formLogin] = Form.useForm();
    const [formCode] = Form.useForm();
    const { onChange } = props
    const [loading, setLoading] = useState(true)
    const [modalType, setModalType] = useState(1)
    const [modalKey, setModalKey] = useState('dongmi')
    const [arcodeFailure, setArcodeFailure] = useState(true)
    const [loginType, setLoginType] = useState('dongmi')
    const [arcodeImg, setArcodeImg] = useState('')
    const [loginError, setLoginError] = useState('')
    const [timeCode, setTimeCode] = useState(60)
    const [tryuseVisible, setTryuseVisible] = useState(false)
    const [ info, setInfo ] = useState({sShowDate: '', sId: '', sToWho: '', sContent: '', sPublisher: '', sPublishTime: '', iShowWay: 1})
    const [ visible, setVisble ] = useState(false)

    const callback = key => {
        setModalKey(key)
        if( key === 'dongmi' ){
            setLoginError('')
            setArcodeFailure(true);
            setArcodeImg('')
        } else {
            _getQRCode()
            clearInterval(timerIDCode.current)
            setTimeCode(60)
        }
    }
    const onFinish = (fieldsValue) => {
        const data = {
            'eType': 1,
            "sAccountId": trim(fieldsValue.username),
            "sPassword": trim(fieldsValue.password),
            "stXPTerminalInfo": {
                iVersion: modalType,
                iSubType: 0,
                iTerminalType: 0
            }
        }
        fakeAccountLogin(data).then((res)=>{
            if(res){
                localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stXPUserInfo))
                localStorage.setItem("rongzheng_dongmi_login",JSON.stringify({
                    username: fieldsValue.username,
                    password: fieldsValue.password,
                    remember: fieldsValue.remember,
                }))
                window.location.href = common  + '/zhiku/'
            }
        })
    }
    const onFinishFailed = (values) => {
        console.log(values)
    }
    const onTelephoneFinish = (fieldsValue) => {
        const reg =/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/
        if(!reg.test(fieldsValue.telephone)){
            formCode.setFields([
                {
                    errors: ['手机号码格式不正确'],
                    name: ['telephone']
                }
            ])
            return;
        }
        const data = {
            sAccountId: fieldsValue.telephone,
            sPassword: fieldsValue.code,
            eType: 2,
            stXPTerminalInfo: {
                iVersion: modalType,
                iSubType: 0,
                iTerminalType: 0
            }
        }
        fakeAccountLogin(data).then(res=>{
            if(res){
                localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stXPUserInfo))
                window.location.href = common + '/zhiku/'
            }
        })

    }
    const handleTryuseCancel = () => {
        setTryuseVisible(false)
        // onChange(false)
        form.setFieldsValue({
            sCompany: "",
            sUserName: "",
            sTitle: "",
            sPhone: "",
            sMail: ""
        })
    }
    const onTelephoneFinishFailed = () => {}
    const onTryuseFinish = (fieldsValue) => {
        const data = {
            ...fieldsValue,
            iProductType: modalType
        }
        applyForRial(data).then((response)=>{
            console.log(" applyForRial " + JSON.stringify(response.data));
            const  res = response.data
            if(res){
                handleTryuseCancel()
                console.log(" applyForRial 222" );
                message.success('提交成功');
            }
        })
    }
    const onTryuseFinishFailed = () => {}
    const _getQRCode = () => {
        setLoading(false)
        clearInterval(timerID.current)
        setLoading(true)
        const data = {}
        getQRCode(data).then(res => {
            setLoading(false)
            if (res) {
                const content = res
                if (content.vQRData.length) {
                    // const array = new Uint8Array(content.vQRData)
                    // const blob = new Blob([array], {
                    //     type: 'application/octet-binary'
                    // })
                    // let oFileReader = new FileReader()
                    // oFileReader.readAsDataURL(blob, 'image/png')
                    // oFileReader.onloadend = e => {
                    //     setArcodeImg(e.target.result)
                    //     setArcodeFailure(false);
                    // }
                    setArcodeImg('data:image/png;base64,'+content.vQRData)
                    setArcodeFailure(false);
                    timerID.current = setInterval(() => {
                        const date = Date.parse(new Date());
                        if(content.iExpireTime<date){
                            setArcodeFailure(true);
                            clearInterval(timerID.current);
                        }else{
                            _checkQRCode({sUuid:content.sUuid});
                        }
                    }, 1000);
                }
            }
        })
    }
    const _checkQRCode = (params, value)=>{
        const paramsData = {...params}
        checkQRCode(paramsData).then(res=>{
            if(res){
                const _data = res;
                if(_data.iStatus===0){
                    clearInterval( timerID.current);
                    localStorage.setItem("rongzheng_dongmi_token", JSON.stringify(res.stIdentyInformation.stXPUserInfo))
                    window.location.href = common + '/zhiku/';
                }
            }
        })
    }
    const forgetPassword = e => {
        e.preventDefault()
        setLoginError('')
        setLoginType('financial')
    }
    const forgetPasswordOther = e => {
        e.preventDefault()
        setLoginError('')
        setLoginType('dongmi')
        clearInterval(timerIDCode.current)
        setTimeCode(60)
    }
    const getPhoneCode = ()=>{
        const reg =/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/
        if(!formCode.getFieldValue("telephone")){
            formCode.setFields([
                {
                    errors: ['请填写手机号'],
                    name: ['telephone']
                }
            ])
            return;
        }
        if(!reg.test(formCode.getFieldValue("telephone"))){
            formCode.setFields([
                {
                    errors: ['手机号码格式不正确'],
                    name: ['telephone']
                }
            ])
            return;
        }
        const data = {sPhoneNum:formCode.getFieldValue("telephone"), stXPTerminalInfo:{iVersion: modalType}}
        doSendPhoneCodePlatForm(data).then(res=>{
            if(res){
                setTimeCode(59)
                timerIDCode.current = setInterval(()=>{
                    setTimeCode((time)=> {
                        if(time === 0){
                            clearInterval(timerIDCode.current)
                            return 60
                        } else {
                            return --time
                        }
                    })
                }, 1000);
            }
        })
    }
    const handleChange = (value)=>{
        setModalType(value)
        localStorage.setItem("rongzheng_version",JSON.stringify({
            modalType:value
        }))
        getUpdateTipInfo(value)
    }
    const getUpdateTipInfo = (iProductType) => {
        const paramsData = {iProductType, iReqFrom: 1}
        getUpdateTip(paramsData).then((response)=>{
            const  res = response.data.rsp
            if(res){
                setInfo(res.stUpdateTip)
            }
        })
    }
    useEffect(()=> {
        const data = localStorage.getItem("rongzheng_version")?JSON.parse(localStorage.getItem("rongzheng_version")):localStorage.getItem("rongzheng_version")
        if(data){setModalType(data.modalType)}
        const user = localStorage.getItem("rongzheng_dongmi_login")?JSON.parse(localStorage.getItem("rongzheng_dongmi_login")):localStorage.getItem("rongzheng_dongmi_login")
        if(user){
            // const current = new Date().getTime();
            // formLogin.setFieldsValue({
            //     username:  current < user.time ? user.username : "",
            //     password: current < user.time ? user.password : "",
            //     remember: current < user.time ? true : false
            // })
            formLogin.setFieldsValue({
                username:  !user.remember ? '' : user.username,
                password: !user.remember ? '' : user.password,
                remember: user.remember
            })
        } else {
            formLogin.setFieldsValue({
                username:  '',
                password: '',
                remember: true
            })
        }
        getUpdateTipInfo(data ? data.modalType : modalType)
        return () => {
            // 组件销毁时，清除定时器
            clearInterval(timerID.current)
            clearInterval(timerIDCode.current)
        };
    }, [])
    return (
        <main className="phoneLogin">
            <div className="tryuse">
                <div>
                    <div className="title">市值管理数智化</div>
                    <div className="color">决策支持系统</div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={logo}/>
                    </div>
                </div>
                <div className="tryuse-content">
                    <Form
                      form={form}
                      onFinish={onTryuseFinish}
                      onFinishFailed={onTryuseFinishFailed}
                    >
                        <Form.Item
                          name="sCompany"
                          rules={[
                              {
                                  required: true,
                                  message: `请输入公司名称!`
                              }
                          ]}
                        >
                            <Input size="large" placeholder={`请输入公司名称`} />
                        </Form.Item>

                        <Form.Item
                          name="sUserName"
                          rules={[
                              {
                                  required: true,
                                  message: '请输入姓名!'
                              }
                          ]}
                        >
                            <Input size="large" placeholder="请输入姓名" />
                        </Form.Item>

                        <Form.Item
                          name="sTitle"
                          rules={[
                              {
                                  required: true,
                                  message: '请输入职位!'
                              }
                          ]}
                        >
                            <Input size="large" placeholder="请输入职位" />
                        </Form.Item>

                        <Form.Item
                          name="sPhone"
                          rules={[
                              {
                                  pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/,
                                  message: '请输入正确的手机号格式!'
                              },
                              {
                                  required: true,
                                  message: '请输入手机号!'
                              }
                          ]}
                        >
                            <Input size="large" placeholder="请输入手机号" />
                        </Form.Item>

                        <Form.Item
                          name="sMail"
                          rules={[
                              {
                                  required: true,
                                  message: '请输入邮箱!'
                              }
                          ]}
                        >
                            <Input size="large" placeholder="请输入邮箱" />
                        </Form.Item>

                        <Form.Item>
                            <Button
                              size="large"
                              type="primary"
                              htmlType="submit"
                              className="login-form-button"
                            >
                                提交申请
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className="tryuse-desc">
                        审核通过后，我们将为您开通试用账号
                    </div>
                </div>
            </div>
            {/*<div className="footer">*/}
            {/*    <span>*/}
            {/*        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%B2%AA%E5%85%AC%E7%BD%91%E5%AE%89%E5%A4%87.png"} />*/}
            {/*        <a*/}
            {/*          target="_blank"*/}
            {/*          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006619"*/}
            {/*        >*/}
            {/*            沪公网安备 31010502006619号*/}
            {/*        </a>*/}
            {/*    </span>*/}
            {/*    |*/}
            {/*    <span>*/}
            {/*        <a*/}
            {/*          target="_blank"*/}
            {/*          href="https://beian.miit.gov.cn/#/Integrated/index"*/}
            {/*        >*/}
            {/*            { window.location.origin.indexOf("ib668.com") > -1 || window.location.origin.indexOf("www.ib668.com") > -1 ? "沪ICP备2023002375号-3 " : "沪ICP备09008594号-3" }*/}
            {/*        </a>*/}
            {/*    </span>*/}
            {/*    <span>Copyright © {moment().format("YYYY")} 上海荣正企业咨询服务（集团）股份有限公司 All rights reserved</span>*/}
            {/*</div>*/}
        </main>
    )
}
export default Login
