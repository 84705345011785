/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
 import axios from 'axios'
 import { message } from 'antd';
 import { tansParams } from './common'
 const errorCode = {
     '401': '认证失败，无法访问系统资源',
     '403': '当前操作没有权限',
     '404': '访问资源不存在',
     'default': '系统未知错误，请反馈给管理员'
 }
 
 axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
 // 创建axios实例
 const requestJava = axios.create({
     // axios中请求配置有baseURL选项，表示请求URL公共部分
     // baseURL: `http://106.14.227.7:9300`,
     // 超时
     timeout: 10000
 })
 requestJava.interceptors.request.use(config => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
     return config
 }, error => {
     console.log(error)
     Promise.reject(error)
 })
 // 响应拦截器
 requestJava.interceptors.response.use(res => {
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
        return res.data
    }
    if (code === 401) {
        message.error(msg);
        return false
    } else if (code === 500) {
        message.error(msg);
        return false
    } else if (code !== 200) {
        message.error(msg);
        return false
    } else {
        // return res.data
        if(res.data.tars_ret === 0){
            return res.data.rsp
        } else {
            message.error(msg);
            return false;
        }
    }
},
error => {
    let { message: sMsg } = error;
    if (sMsg == "Network Error") {
        sMsg = "后端接口连接异常";
    }
    else if (sMsg.includes("timeout")) {
        sMsg = "系统接口请求超时";
    }
    else if (sMsg.includes("Request failed with status code")) {
        sMsg = "系统接口" + sMsg.substr(sMsg.length - 3) + "异常";
    }
    message.error(sMsg)
    return false
   }
 )
 export default requestJava;