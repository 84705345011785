import { useState } from 'react'
import './index.less'
import moment from 'moment'
import { Carousel } from 'antd'
import logo from '../../assets/header/logo.svg'
import Login from './login'
function Dongmi() {
    const [tryuseVisible, setTryuseVisible] = useState(false)
    return (
        <main className="dongmi">
            <div className="contentMain">
                <div className="content">
                    <div className="left">
                        <div className="title">市值管理数智化</div>
                        <div className="color">决策支持系统</div>
                        <div className="img">
                            <div>
                                <div>
                                    <Carousel autoplay dots={false}>
                                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/shanghai1.png"}/>
                                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/shanghai2.png"}/>
                                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/shanghai3.png"}/>
                                    </Carousel>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <Carousel autoplay dots={false}>
                                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/shenzhen1.png"}/>
                                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/shenzhen2.png"}/>
                                    </Carousel>
                                </div>
                            </div>
                            <div><div><img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/bj.png"}/></div></div>
                        </div>
                    </div>
                    <div className="right">
                        <div style={tryuseVisible ? { paddingTop: 33 } : {}} className="loge"><img src={logo}/></div>
                        {  !tryuseVisible && <div className="tip"><div></div><span>董办人的智慧宝库</span><div></div></div> }
                        <Login onChange={(value)=>setTryuseVisible(value)}/>
                    </div>
                </div>
            </div>
            <div className="footer">
                <span>
                    <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%B2%AA%E5%85%AC%E7%BD%91%E5%AE%89%E5%A4%87.png"} />
                    <a 
                        target="_blank" 
                        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006619"
                    >
                        沪公网安备 31010502006619号
                    </a>
                </span>
                |
                <span>
                    <a 
                        target="_blank" 
                        href="https://beian.miit.gov.cn/#/Integrated/index"
                    >
                        { window.location.origin.indexOf("ib668.com") > -1 || window.location.origin.indexOf("www.ib668.com") > -1 ? "沪ICP备2023002375号-3 " : "沪ICP备09008594号-3" }
                    </a>
                </span>
                <span>Copyright © {moment().format("YYYY")} 上海荣正企业咨询服务（集团）股份有限公司 All rights reserved</span>
            </div>
        </main>
    )
}
export default Dongmi
