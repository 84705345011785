import Dongmi from './page/dongmi/index'
import Phone from './page/dongmi/phone'
import './App.less'
import {useState, useEffect} from "react";
function App() {
    // 初始值设置为不是移动端
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkIfMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
          ? true
          : false;
      };
      console.log(checkIfMobile());
      setIsMobile(checkIfMobile());
    }, []);
    return (
        <main className="app">
          { isMobile ?
              <Phone />
            : (
              <Dongmi />
            )}
            {/*<Dongmi />*/}
        </main>
    )
}
export default App
